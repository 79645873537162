

function CommentatorComponent(loaderApi){
	"use strict";
	PIXI.DisplayObjectContainer.call(this);
	PIXI.EventTarget.call(this);

	this.loaderApi = loaderApi

	this.imageHolders = [new PIXI.DisplayObjectContainer(), new PIXI.DisplayObjectContainer()];
	this.imageHolders[0].y = 9
	this.imageHolders[1].y = 9
	this.addChild(this.imageHolders[0])
	this.addChild(this.imageHolders[1])

	this.imageOffset = 54;

	this.percentageBar = new Bar (60, 0xFFFFFF)
	this.percentageBar.y = 53
	this.addChildAt(this.percentageBar, 0)


	this.namesBar0 = new Bar (60, CONFIG.colorBlue)
	this.namesBar0.x = this.imageOffset
	this.namesBar0.y = 27
	this.namesBar0.alpha = 0;
	this.addChildAt(this.namesBar0, 0)

	this.namesBar1 = new Bar(60, CONFIG.colorLightBlue);
	this.namesBar1.x = 100
	this.namesBar1.y = 27
	this.namesBar1.alpha = 0;
	this.addChildAt(this.namesBar1, 0)

	this.namesText = [new PIXI.Text("test", {fill:"#FFFFFF", font:"16px alright_sansultra_italic"}), 
			new PIXI.Text("test", {fill:"#FFFFFF", font:"16px alright_sansultra_italic"})];


	this.percentageText = [new PIXI.Text("test", {fill:"#000000", font:"16px alright_sansultra_italic"}), 
	new PIXI.Text("test", {fill:"#000000", font:"16px alright_sansultra_italic"})];

	this.percentageText[0].y = 52
	this.percentageText[1].y = 52
	this.addChild(this.percentageText[0])
	this.addChild(this.percentageText[1])


	this.imageHolders[0].alpha = 0
	this.imageHolders[1].alpha = 0
	this.percentageBar.alpha = 0;


	this.percentageText[0].alpha = 0;
	this.percentageText[1].alpha = 0;


	this.maskCircle0 = new PixiCircle(38)
	this.maskCircle0.x = 38
	this.maskCircle0.y = 38
	this.imageHolders[0].addChild(this.maskCircle0)

	this.maskCircle1 = new PixiCircle(38)
	this.maskCircle1.y = 38
	this.imageHolders[1].addChild(this.maskCircle1)

	// this.outLine0 = new PIXI.Graphics ();
	// this.outLine0.x = this.maskCircle0.x
	// this.outLine0.y = this.maskCircle0.y
	// this.outLine0.color  = 0xFF0000
	// this.outLine0.lineStyle (4, 0x000643, 1)
	// this.outLine0.drawCircle(0,0, 38)
	// this.imageHolders[0].addChild(this.outLine0)

	// this.outLine1 = new PIXI.Graphics ();
	// this.outLine1.y = this.maskCircle1.y
	// this.outLine1.color  = 0xFF0000
	// this.outLine1.lineStyle (4, 0x000643, 1)
	// this.outLine1.drawCircle(0,0, 38)
	// this.imageHolders[1].addChild(this.outLine1)

  this.__imageLoaded = this.imageLoaded.bind(this)
  this.__imageRemove = this.imageRemove.bind(this)
}


//Copy over prototype --------------------------------------------------------------------------------------

CommentatorComponent.prototype = Object.create(PIXI.DisplayObjectContainer.prototype);
CommentatorComponent.prototype.constructor = CommentatorComponent;

//---------------------------------------------------------------------------------------


// Private function
CommentatorComponent.prototype.imageLoaded = function (e) {

	this.loaderApi.off(LoaderApi.COMPLETE , this.__imageLoaded);

	this.imageSprites = [2];
	this.imageSprites[0] = new PIXI.Sprite(new PIXI.Texture.fromImage(this.imageUrls[0]));
	this.imageSprites[1] = new PIXI.Sprite(new PIXI.Texture.fromImage(this.imageUrls[1]));
	this.imageHolders[0].addChild(this.imageSprites[0]);
	this.imageHolders[1].addChild(this.imageSprites[1]);

	this.imageSprites[0].width = 76
	this.imageSprites[1].width = 76
	this.imageSprites[0].scale.y = this.imageSprites[0].scale.x
	this.imageSprites[1].scale.y = this.imageSprites[1].scale.x
	this.imageSprites[0].x = 0
	this.imageSprites[0].y = 0
	this.imageSprites[1].x = this.maskCircle1.x - this.maskCircle1.radius;
	this.imageSprites[1].y = 0

	this.imageSprites[0].mask = this.maskCircle0;
	this.imageSprites[1].mask = this.maskCircle1;
	// this.imageHolders[0].addChild(this.outLine0)
	// this.imageHolders[1].addChild(this.outLine1)

		// Animation
		// -------------------------------------------------------------------------------------

		this.imageHolders[0].x = -90
		TweenLite.to(this.imageHolders[0] , .4 , {alpha:1, x:0 , delay:0, ease:Strong.easeOut});

		TweenLite.to(this.imageHolders[1] , .4 , {alpha:1, x:0 , delay:0, ease:Strong.easeOut});		

		this.percentageBar.scale.x = 0
		TweenLite.to(this.percentageBar.scale , .3 , {x:1, delay:.4, ease:Strong.easeOut});
		TweenLite.to(this.percentageBar , .2 , {alpha:1, delay:.4, ease:Strong.easeOut});

  	this.namesBar0.scale.x = 0
		TweenLite.to(this.namesBar0.scale , .3 , {x:1, delay:.4, ease:Strong.easeOut});
		TweenLite.to(this.namesBar0 , .2 , {alpha:1, delay:.4, ease:Strong.easeOut});

		this.namesBar1.scale.x = 0
		TweenLite.to(this.namesBar1.scale , .3 , {x:1, delay:.4, ease:Strong.easeOut});
		TweenLite.to(this.namesBar1 , .2 , {alpha:1, delay:.4, ease:Strong.easeOut});   

  	TweenLite.to(this.namesText[0] , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
  	TweenLite.to(this.namesText[1] , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
    TweenLite.to(this.percentageText[0] , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
    TweenLite.to(this.percentageText[1] , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
  }



  CommentatorComponent.prototype.showCommentatorResult = function(names, percentages, images){
  	this.imageUrls = images;
  	this.loaderApi.on(LoaderApi.COMPLETE , this.__imageLoaded);
  	this.loaderApi.load(images);
	
  	this.percentageBar.x = this.imageOffset;

  	this.percentageText[0].setText(percentages[0]);
  	this.percentageText[1].setText(percentages[1]);

		this.namesText[0].setText(names[0]);
		this.namesText[1].setText(names[1]);

		this.namesText[0].y = 28
		this.namesText[1].y = 28
		this.namesText[0].alpha = 0
		this.namesText[1].alpha = 0
		this.addChild(this.namesText[0])
		this.addChild(this.namesText[1])

		//check which name is longest to decide the width ofthe names bars
		var longerNameLength = this.namesText[0].width >= this.namesText[1].width ? this.namesText[0].width : this.namesText[1].width;
  	this.barWidth = longerNameLength + 50
  	this.namesBar1.x = this.imageOffset + this.barWidth;
  	this.namesBar0.redraw(this.barWidth);
  	this.namesBar1.redraw(this.barWidth);

  	this.maskCircle1.x = this.namesBar1.x + this.barWidth + 32;
  	this.percentageBar.redraw(2 * this.barWidth + 10);

  	var nameText0Offset = (this.barWidth - this.namesText[0].width) / 2 + 10;
		this.namesText[0].x = this.percentageText[0].x = this.imageOffset + nameText0Offset;
		var nameText1Offset = this.imageOffset + (this.barWidth - this.namesText[1].width) / 2 + 10;
		this.namesText[1].x = this.percentageText[1].x = this.barWidth + nameText1Offset;

  }



  CommentatorComponent.prototype.hideABResult = function () {
  	TweenLite.to(this.imageHolders[0] , .2 , {alpha:0, x:-20 , delay:.5, ease:Expo.easeOut});
  	TweenLite.to(this.imageHolders[1] , .2 , {alpha:0, x:-20 , delay:.5, ease:Expo.easeOut, onComplete:this.__imageRemove });
  	TweenLite.to(this.percentageBar , .3 , {x:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.percentageBar , .3 , {alpha:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.percentageText[0] , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
  	TweenLite.to(this.percentageText[1] , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
  	TweenLite.to(this.namesBar0.scale , .3 , {x:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.namesBar0 , .3 , {alpha:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.namesBar1.scale , .3 , {x:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.namesBar1 , .3 , {alpha:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.namesText[0] , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
  	TweenLite.to(this.namesText[1] , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
  	
  }


  CommentatorComponent.prototype.imageRemove = function () {
  	this.imageHolders[0].removeChild(this.imageSprites[0])
  	this.imageHolders[1].removeChild(this.imageSprites[1])
  	this.imageSprites = null
  }