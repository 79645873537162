/*
* <author>Anders Haahr</author>
* Usage: Put this directive on a container element and place the video, controls, etc in the container.
* This directive selects the first child of this element (the container) that is a video element, 
* and forces either width or height of the child to equal that of it's parent.
* The choice between width and height is made so that the child element will always fill out the parent,
* meaning that the child element will always be cropped in either right or bottom, 
* unless parent and child share the exact same size ratio.
* This directive also supports the following directives on the containers children:
* fan-graphics: The container for the on air graphics elements
* fan-controls: A custom load and progress bar for the video
* fan-centered-content: A container for content that will be centered horizontally and vertically on the video
*/

angular.module("ScreenModule").directive('fanVideoContainer', function ($window, $timeout) {


	return function(scope, element, attr){
		
		var container = element[0];
		var children = element.children();
		var video;
		var graphics;
		var controls;
		var centeredContent;

		//loop through children to find any of the fan elements that need dom manipulation
		for(var i = 0; i < children.length; i++){
			
			var child = children[i];
			var childAttrs = child.attributes;

			for(var j = 0; j < childAttrs.length; j++){
				var childAttr = childAttrs[j];
				
				if(childAttr.name === "fan-video"){
					video = child;	
				}

				if(childAttr.name === "fan-graphics"){
					graphics = child;
				}

				if(childAttr.name === "fan-controls"){
					controls = child;
				}

				if(childAttr.name === "fan-centered-content"){
					centeredContent = child;
				}
			}
		}


		//get callback when the video has loaded and recalculate the video dimensions
		scope.$on("videoLoaded", setDimensions);


		//get callback when the page has loaded and settled, delay is set in config.js
		scope.$on("delayedInit", setDimensions);

		//register a watch function to get a callbakc when either height or width of the parent element changes
		scope.$watch(function () {
			
			return {
				'height' : container.clientHeight,
				'width' : container.clientWidth
			}

		}, function(newValue, oldValue){

			setDimensions();

		}, true);



		function setDimensions(){
			//Set video dimensions
			if(video && (video.clientWidth < container.clientWidth || video.clientHeight < container.clientHeight)){
				video.style.top = 0;
				video.style.left = 0;
				//Find out whether to use container width or height for video
				var widthRatio = container.clientWidth / video.clientWidth;

				if(video.clientHeight * widthRatio >= container.clientHeight){
					//case: video height should be equal to container height, video will be cropped in the right side
					video.style.width = container.clientWidth + "px";
					video.style.height = "auto";
				}
				else{
					//case: video width should be equal to container width, video will be cropped in the bottom
					video.style.height = container.clientHeight + "px";
					video.style.width = "auto";
				}
			}


			if(graphics){
				//calculate position and margin for the graphics (canvasContainer)
				var canvasTopOffset = container.clientHeight / 50;
				graphics.style.top = (container.offsetTop + canvasTopOffset) + "px";
				graphics.style.height = (container.clientHeight - canvasTopOffset) + "px";

				var canvasLeftOffset = container.clientWidth / 6;
				graphics.style.left = (container.offsetLeft + canvasLeftOffset) + "px";
				graphics.style.width = (container.clientWidth - canvasLeftOffset) + "px"; 	
			}


			if(controls && controls.clientHeight){
				// calculate position for the media controls
				controls.style.top = container.offsetTop + container.clientHeight - controls.clientHeight - CONFIG.layout.mediaControlsBottomMargin + "px";
				controls.style.left = container.offsetLeft + (container.clientWidth - controls.clientWidth) / 2 + "px";	
			}

			if(centeredContent){
				centeredContent.style.position = "absolute";
				centeredContent.style.top = container.offsetTop + (container.clientHeight - centeredContent.clientHeight) / 2 + "px";
				centeredContent.style.left = container.offsetLeft + (container.clientWidth - centeredContent.clientWidth) / 2 + "px";
			}
		}


	}
})