

function RatePlayer(loaderApi){
  "use strict";
  PIXI.DisplayObjectContainer.call(this);
  PIXI.EventTarget.call(this);

  this.loaderApi = loaderApi

  this.imageHolder = new PIXI.DisplayObjectContainer()
  this.imageHolder.y = 30
  this.addChild(this.imageHolder)

  this.winnerBar = new Bar (200, 0x000643)
  this.winnerBar.x = 56
  this.winnerBar.y = 42
  this.addChildAt(this.winnerBar, 0)

  this.winnerNameBar = new Bar (100)
  this.winnerNameBar.x = 64
  this.winnerNameBar.y = this.winnerBar.y + this.winnerNameBar.height -1;
  this.addChildAt(this.winnerNameBar, 0)

  this.fastTxt = new PIXI.Text("MAN OF THE MATCH", {fill:"#e3da02", font:"14px alright_sansultra_italic"});
  this.fastTxt.x = 140
  this.fastTxt.y = this.winnerBar.y + (this.winnerBar.height - this.fastTxt.height) / 2;
  this.addChild(this.fastTxt)

  this.winnerTxt = new PIXI.Text("test", {fill:"#000000", font:"16px alright_sansultra_italic"});
  this.winnerTxt.x = 100
  this.winnerTxt.y = this.winnerNameBar.y + (this.winnerNameBar.height - this.winnerTxt.height) / 2;
  this.addChild(this.winnerTxt)


  this.imageHolder.alpha = 0

  this.winnerTxt.alpha = 0
  this.fastTxt.alpha = 0
  this.winnerNameBar.alpha = 0
  this.winnerBar.alpha = 0


  this.maskCircle = new PixiCircle(38)
  this.maskCircle.x = 106*.5
  this.maskCircle.y = 71*.5
  //this.maskCircle.alpha = 0
  this.imageHolder.addChild(this.maskCircle)

  this.outLine = new PIXI.Graphics ();
  this.outLine.x = this.maskCircle.x
  this.outLine.y = this.maskCircle.y
  this.outLine.color  = 0xFF0000
  this.outLine.lineStyle (4, 0x000643, 1)
  this.outLine.drawCircle(0,0, 38)
  this.imageHolder.addChild(this.outLine)


  // Vars
  this.imageSpriteYpos = 3;
  // call back function 
  this.__imageLoaded = this.imageLoaded.bind(this)
  this.__imageRemove = this.imageRemove.bind(this)

}


//Copy over prototype --------------------------------------------------------------------------------------

RatePlayer.prototype = Object.create(PIXI.DisplayObjectContainer.prototype);
RatePlayer.prototype.constructor = RatePlayer;

//---------------------------------------------------------------------------------------


// Private function
RatePlayer.prototype.imageLoaded = function (e) {

  this.loaderApi.off(LoaderApi.COMPLETE , this.__imageLoaded);

  var imageTexture;

  if(this.imageUrl){
    imageTexture = new PIXI.Texture.fromImage(this.imageUrl);
  }
  else if(this.image){
    var base = new PIXI.BaseTexture(this.image);
    imageTexture = new PIXI.Texture(base);
  }

  this.imageSprite = new PIXI.Sprite(imageTexture);
  this.imageHolder.addChild(this.imageSprite);


  if(this.imageSprite.width >= 100 ){
    var k = 81/this.imageSprite.width
    var kk = 81/this.imageSprite.height
    this.imageSprite.width = 130
    this.imageSprite.scale.y = this.imageSprite.scale.x
    this.imageSprite.x = -10
    this.imageSprite.y = 0
  }else{
    this.imageSprite.x = 3
    this.imageSprite.y = 3
  }





  this.imageSprite.mask = this.maskCircle;
  this.imageHolder.addChild(this.outLine)

// Animation
// -------------------------------------------------------------------------------------

this.imageHolder.x = -90
TweenLite.to(this.imageHolder , .4 , {alpha:1, x:0 , delay:0, ease:Strong.easeOut});

    this.winnerBar.scale.x = 0
    TweenLite.to(this.winnerBar.scale , .3 , {x:1, delay:.4, ease:Strong.easeOut});
    TweenLite.to(this.winnerBar , .2 , {alpha:1, delay:.4, ease:Strong.easeOut});

    this.fastTxt.x = 70;
    TweenLite.to(this.fastTxt , .3 , {x:100, alpha:1, delay:.5, ease:Strong.easeOut});
    
    this.winnerNameBar.scale.x = 0
    TweenLite.to(this.winnerNameBar.scale , .4 , {x:1, delay:.6, ease:Strong.easeOut});
    TweenLite.to(this.winnerNameBar , .4 , {alpha:1, delay:.6, ease:Strong.easeOut});


    TweenLite.to(this.winnerTxt , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});

  }


  RatePlayer.prototype.showWinnerFromUrl = function (displayText, imageUrl, winnerFullName) {
    this.fastTxt.setText(displayText);
    this.showWinner(displayText, winnerFullName);
    this.imageUrl = imageUrl;
    var arr = [imageUrl];
    this.loaderApi.on(LoaderApi.COMPLETE , this.__imageLoaded);
    this.loaderApi.load(arr);
  }



  RatePlayer.prototype.showWinnerFromImage = function(displayText, image, winnerFullName){
    this.showWinner(displayText, winnerFullName);
    this.image = image;
    this.imageLoaded();
  }



  RatePlayer.prototype.showWinner = function(displayText, winnerFullName){
    this.fastTxt.setText(displayText);
    this.winnerTxt.setText (winnerFullName.toUpperCase());
    this.winnerNamebarWidth = this.winnerTxt.width +50;
    this.winnerNameBar.redraw(this.winnerNamebarWidth );
  }



  RatePlayer.prototype.hideWinner = function () {
    TweenLite.to(this.imageHolder , .2 , {alpha:0, x:-20 , delay:.5, ease:Expo.easeOut, onComplete:this.__imageRemove });

    TweenLite.to(this.winnerBar.scale , .3 , {x:0, delay:.18, ease:Expo.easeOut});
    TweenLite.to(this.winnerBar , .3 , {alpha:0, delay:.18, ease:Expo.easeOut});
    TweenLite.to(this.fastTxt , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
    TweenLite.to(this.winnerNameBar.scale , .4 , {x:0, delay:0, ease:Expo.easeOut});
    TweenLite.to(this.winnerNameBar , .4 , {alpha:0, delay:0, ease:Expo.easeOut});
    TweenLite.to(this.winnerTxt , .4 , {alpha:0, delay:0, ease:Expo.easeOut});
  }



  RatePlayer.prototype.imageRemove = function () {
    this.imageHolder.removeChild(this.imageSprite)
    this.imageSprite = null
  }


// function RatePlayer(loaderApi){
// 	"use strict";
// 	PIXI.DisplayObjectContainer.call(this);
// 	PIXI.EventTarget.call(this);

// 	this.loaderApi = loaderApi;

// 	this.imageHolder = new PIXI.DisplayObjectContainer()
// 	this.imageHolder.y = 60;
// 	this.addChild(this.imageHolder)


// 	this.gamePlayer = new PIXI.Sprite (new PIXI.Texture.fromImage (ASSETS.kampenSpiller))
// 	this.gamePlayer.x = 47
// 	this.gamePlayer.y = 28
// 	this.addChildAt(this.gamePlayer, 0)

// 	this.winnerNameBar = new Bar (100 )
// 	this.winnerNameBar.x = 50
// 	this.winnerNameBar.y = 54
// 	this.addChildAt(this.winnerNameBar, 0)

// 	this.winnerTxt = new PIXI.Text("test", {fill:"#000000", font:"16px alright_sansultra_italic"});
// 	this.winnerTxt.x = this.winnerNameBar.x +70
// 	this.winnerTxt.y = 62
// 	this.addChild(this.winnerTxt)

// 	this.logo = new PIXI.Sprite (new PIXI.Texture.fromImage (ASSETS.questionBarLogo))
// 	this.logo.x = this.winnerNameBar.width
// 	this.logo.y = this.winnerNameBar.y
// 	this.addChildAt (this.logo,0)


// 	this.imageHolder.alpha = 0
// 	this.logo.alpha = 0
// 	this.gamePlayer.alpha = 0
// 	this.winnerTxt.alpha = 0
// 	this.winnerNameBar.alpha = 0


// 	this.testXpos = 10



// 	// call back function
// 	this.__imageLoaded = this.imageLoaded.bind(this)
// 	this.__imageRemove = this.imageRemove.bind(this)

// }


// //Copy over prototype --------------------------------------------------------------------------------------

// RatePlayer.prototype = Object.create(PIXI.DisplayObjectContainer.prototype);
// RatePlayer.prototype.constructor = RatePlayer;

// //---------------------------------------------------------------------------------------


// // Private function
// RatePlayer.prototype.imageLoaded = function (e) {

// 	this.loaderApi.off(LoaderApi.COMPLETE , this.__imageLoaded)

// 	var imageTexture = new PIXI.Texture.fromImage(this.imageUrl)
// 	this.imageSprite = new PIXI.Sprite(imageTexture)

// 	this.imageSprite.x = 0
// 	this.imageSprite.y = -2
// 	this.imageSprite.scale.x = .45
// 	this.imageSprite.scale.y = .45
// 	this.imageHolder.addChild(this.imageSprite)


// 	// Animation
// 	// -------------------------------------------------------------------------------------

// 	this.imageHolder.x = -90
// 	TweenLite.to(this.imageHolder , .4 , {alpha:1, x:-35 , delay:0, ease:Strong.easeOut});

// 	this.gamePlayer.x = 150
// 	TweenLite.to(this.gamePlayer , .4 , {alpha:1, x:47, delay:.1, ease:Strong.easeOut});

// 	TweenLite.to(this.logo , .4 , {alpha:1, x:this.winnerNameBarEndWidth +50, delay:.5, ease:Strong.easeOut});

// 	this.winnerNameBar.scale.x = 0
// 	TweenLite.to(this.winnerNameBar.scale , .4 , {x:1, delay:.5, ease:Strong.easeOut});
// 	TweenLite.to(this.winnerNameBar , .4 , {alpha:1, delay:.5, ease:Strong.easeOut});
// 	TweenLite.to(this.winnerTxt ,.8 , {alpha:1, delay:.6, ease:Strong.easeOut});


// }



// RatePlayer.prototype.showPlayerRating = function (imageUrl, winnerFullName) {
// 	this.gamePlayer.scale.x = 1
// 	this.gamePlayer.alpha = 0
// 	this.winnerTxt.setText (winnerFullName.toUpperCase());
// 	this.winnerNameBarEndWidth = this.winnerTxt.width + 90
// 	this.winnerNameBar.redraw(this.winnerNameBarEndWidth);
// 	this.imageUrl = imageUrl
// 	var arr = [imageUrl]
// 	this.loaderApi.on(LoaderApi.COMPLETE , this.__imageLoaded )
// 	this.loaderApi.load(arr)

// }


// RatePlayer.prototype.hidePlayerRating= function () {
// 	TweenLite.to(this.imageHolder , .3 , {alpha:0, x:-50 , delay:.4, ease:Expo.easeOut, onComplete:this.__imageRemove});

// 	TweenLite.to(this.winnerNameBar.scale , .4 , {x:0, delay:.1, ease:Expo.easeOut});

// 	TweenLite.to(this.logo, .2 , {alpha:0, x:20 , delay:.15, ease:Expo.easeOut});
// 	TweenLite.to(this.gamePlayer.scale, .2 , { x:0 , delay:.1, ease:Expo.easeOut});


// 	TweenLite.to(this.winnerTxt , .4 , {alpha:0, delay:0, ease:Expo.easeOut});
// }


// RatePlayer.prototype.imageRemove = function () {
// 	this.imageHolder.removeChild(this.imageSprite)
// 	this.imageSprite = null
// }
