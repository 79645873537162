var fanDemoApp = angular.module("ScreenModule", []);

fanDemoApp.run(function(DatastringFactory){
	console.log("run function");

	//init onair graphics lib
	var onAirGraphics = new OnAirGraphics ();

	//start polling the datastring 
	DatastringFactory.startPolling(CONFIG.datastringUrl, 1000, onAirGraphics);
})