/*
* <author>Anders Haahr</author>
* The Angular controller for the ScreenModule. 
*/

angular.module("ScreenModule").controller("ScreenCtrl", ["$rootScope", "$scope", "$interval", "$timeout", "$sce", function($rootScope, $scope, $interval, $timeout, $sce){

	
	var vm = this;


	function init(){
		
		vm.videos = fanVideos;
		if(!vm.selectedVid){
			vm.selectedVid = vm.videos[0];
		}

		//attach page loade listener
		angular.element(document).ready(pageLoaded);

		//get video and media elements
		vm.video = document.getElementById("video");
		vm.playBar = document.getElementById("play-bar");
		vm.loadBar = document.getElementById("load-bar")

		//attach listener to get a callback hen video has loaded
		vm.video.addEventListener("loadedmetadata", videoMetaDataLoaded);

		//attach listener to get a callback when the mouse is moved over the video
		document.addEventListener("mousemove", mouseMoved);

		//catch the event thrown by fanKeyInterept directory
		$scope.$on('keypress', handleKeyboardEvent);

	}


	function handleKeyboardEvent(event, args){
		if(args.which === 32){
			vm.playPause();
		}
	}


	function mouseMoved(){
		toggleControls(true);
	}


	function toggleControls(enabled){
		if(enabled){
			vm.enableControls = true;
			if(vm.playing){
				vm.showControls = true;
				$timeout.cancel(vm.mediaControlTimeout);
				vm.mediaControlTimeout = $timeout(function() {
					toggleControls(false);
				}, CONFIG.controlsFadeout);
			}
		}
		else{
			$timeout.cancel(vm.mediaControlTimeout);
			vm.showControls = false;
		}
	}


	function pageLoaded() {
		$timeout(delayedInit, CONFIG.initDelay);
	}


	function videoMetaDataLoaded(){
		//broadcast the video loaded event so it can be picked up in a directive
		$rootScope.$broadcast("videoLoaded");
		
		//attach listener to get progress on the video play / load status
		vm.video = document.getElementById("video");
		vm.video.addEventListener("progress", playing);

		//Set the playMax and loadMax vars so that the load / progress bar will display correctly
		vm.playMax = vm.loadMax = vm.video.duration.toString();
	}


	function delayedInit() {
		$rootScope.$broadcast("delayedInit");
		// window.dispatchEvent(new Event('resize'));
	}


	function playing(arg1){
		if(this.buffered.length >= 1){
			vm.loadBar.value = this.buffered.end(0);
		}

		// console.log("Played: " + this.currentTime + " / " + vm.playMax);
		vm.playBar.value = this.currentTime.toString();
	}


	vm.getVideoUrl = function() {
    return $sce.trustAsResourceUrl(vm.selectedVid.url);
  }


  vm.vidSelected = function(vid){
  	vm.selectedVid = vid;
  	vm.burgerMenuOpen = false;
  }


	vm.burgerMenuClicked = function(event){
		if(vm.playing){
			vm.playPause();
		}
		else{
			vm.burgerMenuOpen = !vm.burgerMenuOpen;
		}
		event.stopPropagation();
	}


	vm.videoClose = function(){
		vm.playPause();
	}


	vm.playPause = function(){
		if(!vm.playing){
			vm.video.play();
			vm.playing = true;
			toggleControls(true);
		}
		else{
			vm.video.pause();
			vm.playing = false;
			toggleControls(false);
		}
	}



	init();

}]);