/**
 * @author ddennis.dk - aka fantastisk.dk/works aka meresukker.dk
 */


// VARS ------------------------------------------------------------

function OnAirGraphics () {
    "use strict";
    PIXI.DisplayObjectContainer.call(this);
    PIXI.EventTarget.call(this);
};

//---------------------------------------------------------------------------------------
	OnAirGraphics.prototype = Object.create(PIXI.DisplayObjectContainer.prototype);
	OnAirGraphics.prototype.constructor = OnAirGraphics;
//---------------------------------------------------------------------------------------

	OnAirGraphics.prototype.init = function(){

		stage.interactive = true; 		
		renderer.resize(1024,576)
				
		this.assetIsLoaded = false

		// LOAD ASSETS --------------------------------------------------------
		var assetsToLoad = ASSETS.toArray(CONFIG.images);
		this.loadApi = new LoaderApi ();

		this.__assetsLoaded = this.assetsLoaded.bind (this)
		this.loadApi.on(LoaderApi.COMPLETE, this.__assetsLoaded );
		this.loadApi.load (assetsToLoad);

		// only for testing
		this.setupTestBtns()

	};



    OnAirGraphics.prototype.cacheBust = function(imageUrl){
        return imageUrl + "?" + Math.random()
    }



//---------------------------------------------------------------------------------------


	OnAirGraphics.prototype.assetsLoaded  = function(){
		this.assetIsLoaded = true;

		this.loadApi.off(LoaderApi.COMPLETE, this.__assetsLoaded );

		var backgroundText = new PIXI.Texture.fromImage (ASSETS.background)
		this.background = new PIXI.Sprite(backgroundText)
		this.background.alpha = 0;
		stage.addChild(this.background)

 	// 	var logoTexture = new PIXI.Texture.fromImage (ASSETS.logo) 
		// this.logo = new PIXI.Sprite(logoTexture)
		// this.logo.x = 35;
		
		this.elementYpos = 50;
		// stage.addChild(this.logo)

		var maskBar = new Bar(800, 0xCC0000)
		maskBar.x = 20;
		maskBar.y = this.elementYpos;
		stage.addChild(maskBar)

		var q = "Where did Center Michael Jordan play college basketball?"
		var questionBar = new Questionbar(q)
		questionBar.x = 20;
		questionBar.y = this.elementYpos;
		stage.addChild(questionBar)		
		this.questionBar = questionBar
		questionBar.mask = maskBar
		
		var winnerComponent = new WinnerComponent(this.loadApi)
		stage.addChild(winnerComponent)
		winnerComponent.x = 20;
		winnerComponent.y = this.elementYpos - 40
		this.winnerComponent = winnerComponent

		var abComponent = new ABComponent(this.loadApi);
		stage.addChild(abComponent);
		abComponent.x = 20;
		abComponent.y = this.elementYpos - 40;
		this.abComponent = abComponent;

		var commentatorComponent = new CommentatorComponent(this.loadApi);
		stage.addChild(commentatorComponent);
		commentatorComponent.x = 20;
		commentatorComponent.y = this.elementYpos - 40;
		this.commentatorComponent = commentatorComponent;

		this.rateMoment = new RateMomentComponent()
    this.rateMoment.x = 20
    this.rateMoment.y = 35
    stage.addChild (this.rateMoment)

		this.ratePlayer = new RatePlayer(this.loadApi)
		this.ratePlayer.x = 20
		this.ratePlayer.y = 10
		stage.addChild (this.ratePlayer )

		this.gameWinner = new GameWinner(this.loadApi)
		this.gameWinner.x = 20
		this.gameWinner.y = 10
		stage.addChild (this.gameWinner )

    this.testCount = 0
    var imageURL = "images/randomPerson.jpg"

	}



/** ---------------------------------------------------------------
 *
 *	OnAirGraphics function for displaying a Moment rating
 *
 */

OnAirGraphics.prototype.showMomentRating = function(value){
	this.showBackground()
	this.rateMoment.showMomentRating(value)
}

OnAirGraphics.prototype.hideMomentRating  = function(){
	this.hideBackground()
	this.rateMoment.hideMomentRating()
}


/** ---------------------------------------------------------------
 *	GAME WINNER
 */

OnAirGraphics.prototype.showGameWinner = function(imageUrl, name){
	this.showBackground()
	this.gameWinner.show(this.cacheBust(imageUrl) , name)
}

OnAirGraphics.prototype.hideGameWinner = function(){
	this.hideBackground();
	this.gameWinner.hide()
}



/** ---------------------------------------------------------------
 *	OnAirGraphics function for displaying a PLAYER rating
 */

OnAirGraphics.prototype.showRatePlayer= function(displayText, imageUrl, playerName){
	this.showBackground()
	// this.ratePlayer.showPlayerRating(this.cacheBust(imageUrl), playerName)
	this.ratePlayer.showWinnerFromUrl(displayText, imageUrl, playerName);
}

OnAirGraphics.prototype.hideRatePlayer  = function(){
	this.hideBackground();
	this.ratePlayer.hideWinner();
	// this.ratePlayer.hidePlayerRating();
}


/** ---------------------------------------------------------------
*
*	OnAirGraphics function for displaying a question, caling showQuestion 
*	without calling a hideQuestion will make it look wierd
*
*/

	OnAirGraphics.prototype.showQuestion  = function(questionText){
		this.showBackground()
		this.questionBar.show(questionText)
	}


	OnAirGraphics.prototype.hideQuestion  = function(){
		this.hideBackground()
		this.questionBar.hide()

	}


 /** ---------------------------------------------------------------
 *
 *	OnAirGraphics function for displaying a winner. 
 *	The profile image will be preloaded before the OnAirGraphics is shown.
 * 
 */

	OnAirGraphics.prototype.hideWinner  = function(){
		this.winnerComponent.hideWinner()
		this.hideBackground()
	}


	OnAirGraphics.prototype.showWinner  = function(displayText, image , fullname){
		this.showBackground()
		if(typeof image === 'string'){
			this.winnerComponent.showWinnerFromUrl(displayText, image, fullname);
		}else{
			this.winnerComponent.showWinnerFromImage(displayText, image, fullname);
		}
	}


	/** ---------------------------------------------------------------
 *
 *	OnAirGraphics function for displaying the result of an A vs B event. 
 *	The profile images will be preloaded before the OnAirGraphics is shown.
 * 
 */

	OnAirGraphics.prototype.hideAB  = function(){
		this.abComponent.hideABResult();
		this.hideBackground();
	}


	OnAirGraphics.prototype.showAB  = function(names, percentages, images){
		this.showBackground();
		this.abComponent.showABResult(names, percentages, images);
	}


	/** ---------------------------------------------------------------
 *
 *	OnAirGraphics function for displaying the result of a commentator event
 *	The profile images will be preloaded before the OnAirGraphics is shown.
 * 
 */

	OnAirGraphics.prototype.hideCommentator  = function(){
		this.commentatorComponent.hideABResult();
		this.hideBackground();
	}


	OnAirGraphics.prototype.showCommentator  = function(names, percentages, images){
		this.showBackground();
		this.commentatorComponent.showCommentatorResult(names, percentages, images);
	}



// BACKGROUND
//---------------------------------------------------------------------------------------

	OnAirGraphics.prototype.showBackground  = function(questionText){
		//TweenMax.to(this.background, 1, {alpha:1, ease:"Expo.easeOut"});
	}

	OnAirGraphics.prototype.hideBackground  = function(questionText){
		//TweenMax.to(this.background,2, {alpha:0,delay:.2,  ease:"Expo.easeOut"});

	}

//-------------------------------------------------------------------
// THE REST IS ONLY FOR TESTING 
//-------------------------------------------------------------------

	OnAirGraphics.prototype.setupTestBtns  = function(){
		// this.__hideClick = this.hideClick.bind(this)
		// this.__showClick = this.showClick.bind(this)
		// this.__showWinnerClick = this.showWinnerClick.bind(this)
		// this.__hideWinnerClick = this.hideWinnerClick.bind(this)
		// this.__showRatingClick = this.showRatingClick.bind(this)
		// this.__hideRatingClick = this.hideRatingClick.bind(this)
		// this.__showRatePlayerClick = this.showRatePlayerClick.bind(this)
		// this.__hideRatePlayerClick = this.hideRatePlayerClick.bind(this)

		// this.__hideGameWinnerClick= this.hideGameWinnerClick.bind(this)
		// this.__showGameWinnerClick = this.showGameWinnerClick.bind(this)

		// var showBtn = new TextBtn("Show question")
		// showBtn.interactive = true
		// showBtn.x = 20
		// showBtn.y = 500
		// stage.addChild(showBtn)
		// showBtn.click = this.__showClick
		
		// var hideBtn = new TextBtn("Hide question");
		// hideBtn.x = 20
		// hideBtn.y = 530
		// stage.addChild(hideBtn)
		// hideBtn.click = this.__hideClick;

		// var showRating = new TextBtn("show moment rating")
		// showRating.interactive = true
		// showRating.x = 510
		// showRating.y = 500
		// stage.addChild(showRating)
		// showRating.click = this.__showRatingClick

		// var hideRating = new TextBtn("hide moment rating")
		// hideRating.interactive = true
		// hideRating.x = 510
		// hideRating.y = 530
		// stage.addChild(hideRating)
		// hideRating.click = this.__hideRatingClick

		// var showPlayerRating = new TextBtn("show player Rating")
		// showPlayerRating.interactive = true
		// showPlayerRating.x = 800
		// showPlayerRating.y = 200
		// stage.addChild(showPlayerRating)
		// showPlayerRating.click = this.__showRatePlayerClick


		// var hidePlayerRating = new TextBtn("hide player Rating")
		// hidePlayerRating.interactive = true
		// hidePlayerRating.x = 800
		// hidePlayerRating.y = 230
		// stage.addChild(hidePlayerRating )
		// hidePlayerRating.click = this.__hideRatePlayerClick


		// var showWinner = new TextBtn("show Winner")
		// showWinner.interactive = true
		// showWinner.x = 300
		// showWinner.y = 500
		// stage.addChild(showWinner)
		// showWinner.click = this.__showWinnerClick

		// var hideWinner = new TextBtn("Hide Winner")
		// hideWinner.interactive = true
		// hideWinner.x = 300;
		// hideWinner.y = 530;
		// stage.addChild(hideWinner)
		// hideWinner.click = this.__hideWinnerClick


		// var showGameWinner = new TextBtn("show game Winner")
		// showGameWinner.interactive = true
		// showGameWinner.x = 300
		// showGameWinner.y = 400
		// stage.addChild(showGameWinner)
		// showGameWinner.click = this.__showGameWinnerClick

		// var hideGameWinner = new TextBtn("Hide game Winner")
		// hideGameWinner.interactive = true
		// hideGameWinner.x = 300;
		// hideGameWinner.y = 430;
		// stage.addChild(hideGameWinner)
		// hideGameWinner.click = this.__hideGameWinnerClick


	}

//-------------------------------------------------------

	OnAirGraphics.prototype.showClick  = function(){		
		var q = "Where did Michael Jordan go to college"
		this.showQuestion(q)
	}

	OnAirGraphics.prototype.hideClick  = function(){
		this.hideQuestion()

	}

	OnAirGraphics.prototype.hideWinnerClick  = function(){
		this.hideWinner()
	}


	OnAirGraphics.prototype.showWinnerClick  = function(){
		var imageURL = "images/randomPerson.jpg"
		this.showWinner (imageURL, "Dennis Hallund Christoffersen");
	}

	OnAirGraphics.prototype.showRatingClick  = function(){
		this.showMomentRating (3)
	}

	OnAirGraphics.prototype.hideRatingClick= function(){
		this.hideMomentRating()
	}

	OnAirGraphics.prototype.showRatePlayerClick = function(){
    var p = ""
        if(this.testCount == 0 ){
            p = "images/randomPerson.jpg?"+Math.random()
            this.testCount++
        }else{
            p = "images/winner.jpg?"+Math.random()
            this.testCount = 0
        }
        console.log (" OnAirGraphics.js > p = " , p);
		this.showRatePlayer(p, "James Bond")
	}

	OnAirGraphics.prototype.hideRatePlayerClick = function(){

		this.hideRatePlayer()
	}

//---------------------------------------------------------------------------------------


	OnAirGraphics.prototype.showGameWinnerClick = function(){
		console.log("show game winner")
		var p = "images/randomPerson.jpg"
		this.showGameWinner(p, "Egon Olsen")
	}

	OnAirGraphics.prototype.hideGameWinnerClick = function(){

		this.hideGameWinner()
	}

