var fanVideos = [
	
	{
		name: "NBA",
		url: "https://s3-eu-west-1.amazonaws.com/groupmfanimages/demovideo/video2.mp4",
	},
	{
		name: "Citronkylling",
		url: "https://s3-eu-west-1.amazonaws.com/groupmfanimages/demovideo/GAP5_Citronkylling_1.mp4"
	},
	{
		name: "Kikærtetortilla",
		url: "https://s3-eu-west-1.amazonaws.com/groupmfanimages/demovideo/GAP3_Kikaertetortilla_1.mp4"
	}

]