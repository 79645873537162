/*
* <author>Anders Haahr</author>
* Usage: Put this directive on the body tag to broadcast the event 'keypress' 
* whenever a keyboard key is pressed while focus is on the page
*/

angular.module("ScreenModule").directive('fanKeyIntercept', function ($document, $rootScope) {
  return {

    restrict: 'A',

    link: function (scope, element, attrs) {
      $document.bind('keypress', function(e){
        $rootScope.$broadcast('keypress', e)
      })
    }
  }
});