var testDatastring = 

{
	data: {
		Root: {
			item: {
				timestamp: "Fri Oct 02 2015 10:23:27 GMT+0000 (UTC)",
				question: "",
				winnername: "Der var ingen vinder",
				avatar: "",
				action: "GAMEEND",
				result: "",
				imageurl: ""
			}
		}
	}
}