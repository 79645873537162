/*
* <author>Anders Haahr</author>
*/

angular.module("ScreenModule").controller("TestCtrl", ["$scope", function($scope){

	var vm = this;
	vm.onAirGraphics = new OnAirGraphics ();
	vm.onAirGraphics.init();

	$scope.showAB = function(){
		vm.onAirGraphics.showAB(CONFIG.abText, ["50%", "50%"], CONFIG.abImages);		
	}

	$scope.hideAB = function(){
		vm.onAirGraphics.hideAB();
	}

	$scope.showCommentator = function(){
		vm.onAirGraphics.showCommentator(CONFIG.commentators, ["50%", "50%"], CONFIG.commentatorImages);			
	}

	$scope.hideCommentator = function(){
		vm.onAirGraphics.hideCommentator();			
	}

	$scope.showQuestion = function(){
		vm.onAirGraphics.showQuestion("This is a test question");
	}

	$scope.hideQuestion = function(){
		vm.onAirGraphics.hideQuestion();
	}


}]);