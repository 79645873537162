angular.module("ScreenModule").factory('DatastringFactory', function($interval, $http, $timeout){

	
	var firstTimeStamp;
	var lastTimeStamp;
	var intervalInst;
	var graphics;


	return {
		startPolling:startPolling,
		stopPolling: stopPolling
	}	


	//start polling the datastring and call the parseDatastring function when changes are detected
	function startPolling(url, interval, onAirGraphics){

		onAirGraphics.init();
		onAirGraphics.setupTestBtns();

		graphics = onAirGraphics;

		// $timeout(function(){
		// 	// graphics.showRatePlayer("images/player_test.jpg", "Tarik Black");
		// 	var img = new Image();
		// 	img.src = "images/player_test.jpg";
		// 	// showWinnerFromPath("MAN OF THE MATCH", "images/player_test.jpg" , "Kenneth");
		// 	graphics.showRatePlayer("MAN OF THE MATCH", "images/player_test.jpg", "Tarik Black");
		// }, 1000);

		intervalInst = $interval(function(){
			
			$http.get(url).success(function(data){
				var datastring = data.data.Root.item;
				// console.log("action: ", datastring.action);
				if(!firstTimeStamp){
					firstTimeStamp = datastring.timestamp;
				}	
				
				if(datastring.timestamp !== firstTimeStamp && datastring.timestamp !== lastTimeStamp){
					parseDatastring(datastring);
				}
			});
			// parseDatastring(testDatastring.data.Root.item);
		}, interval);

	}


	//stop polling the datastring 
	function stopPolling(){
		$interval.cancel(intervalInst);
		intervalInst = undefined;
	}


	//Parse the data and call the appropriate functions from the onair graphics tool
	function parseDatastring(data){
		lastTimeStamp = data.timestamp;
		var question = data.question;
		var winnername = data.winnername;
		var avatar = data.avatar;
		var action = data.action;
		var result = data.result;
		var imageUrl = data.imageurl;

		
		if(action){

			//Checking the content of the action node of the datastring
			switch(action){


				//Case: Quiz event has startet
				case "QUIZSTART":
					console.log("QUIZSTART");
					graphics.showQuestion(question);
					setClearTimer(function(){
						graphics.hideQuestion();
					}, CONFIG.displayTime)
					break;



				//Case: Quiz event has ended
				case "QUIZEND":
					console.log("QUIZEND ::: " + winnername + " ::: " + avatar);
					graphics.hideQuestion();
					if(winnername && avatar){
						console.log("showing image from " , imageUrl);

						showWinnerFromUrl(CONFIG.fastestAnswer, imageUrl, winnername);

					}
					break;



				//Case: Rating event has ended
				case "RATINGEND":
					console.log("RATINGEND");
					if(avatar){
						console.log("rate player");
						graphics.showRatePlayer(CONFIG.testingfanManOfTheMatch, result);
					}
					else{
						console.log("rate moment result: " + result);
						graphics.showMomentRating(parseInt(result * 2));
						setClearTimer(function(){
							graphics.hideMomentRating();
						}, CONFIG.displayTime);
					}
					break;



				//Case: Commentator battle
				case "KOMMEND":
					console.log("RREND || KOMMEND");

					var percentages = result.split("-");
					showCommentatorEvent(CONFIG.commentators, percentages, CONFIG.commentatorImages);

					break;



				//Case: Man of the match
				case "PLAYEREND":
					console.log("PLAYEREND");

					showManOfTheMatch("MAN OF THE MATCH", imageUrl, winnername);

					break;



				//CASE: End of A vs B event
				case "ABEND":
					console.log("ABEND");

					var percentages = result.split("-");
					showABEvent(CONFIG.abText, percentages, CONFIG.abImages);

					break;


				//Case: End of the game
				case "GAMEEND":
					console.log("GAMEEND");
					
					showWinnerFromUrl(CONFIG.fanOfTheDay, imageUrl, winnername);

					break;


				//Case: Ready text
				case "KLAR":
					console.log("KLAR");
					
					graphics.showQuestion(CONFIG.getReady);
					setClearTimer(function(){
						graphics.hideQuestion();
					}, CONFIG.displayTime)

					break;

			}

		}

	}


	/* ----------- Onair helper functions ------------------------------------------------------------ */

	function showABEvent(text, percentages, images)  {
		graphics.showAB(text, percentages, images);
		setClearTimer(function(){
			graphics.hideAB();
		}, CONFIG.displayTime)
	}


	function showCommentatorEvent(names, percentages, images)  {
		graphics.showCommentator(names, percentages, images);
		setClearTimer(function(){
			graphics.hideCommentator();
		}, CONFIG.displayTime)
	}


	function showWinnerFromPath(displayText, imgagePath, winnername){
		graphics.showWinner(displayText, imgagePath, winnername);
		setClearTimer(function(){
			graphics.hideWinner();
		}, CONFIG.displayTime);
	}



	function showWinnerFromUrl(displayText, imageUrl, winnername){
		loadImage(imageUrl, function(img){
			graphics.showWinner(displayText, img, winnername);
			setClearTimer(function(){
				graphics.hideWinner();
			}, CONFIG.displayTime)
		})
	}


	function showManOfTheMatch(displayText, imageUrl, playerName){
		graphics.showRatePlayer(displayText, imageUrl, playerName);
		setClearTimer(function(){
			graphics.hideRatePlayer();
		}, CONFIG.displayTime);
	}



	function loadImage(imageUrl, callback) {
		
		var manipulatedImageUrl = imageUrl.replace("redirect=1", "redirect=0");
						
		$http.get(manipulatedImageUrl).success(function(res){
			//create an image element from the Fb image and use it as param to the PIXI based graphics lib.
			//This is used as a workaround because the graphics lib did not take kindly to the FB url
			var img = null;
			if(res.data && res.data.url){
				var img = new Image();
				img.src = res.data.url;
			}

			callback(img);
		});

	}


	function setClearTimer(callback, time){
		$timeout(function() {
			console.log("calling timed callback");
			callback();
		}, time);
	}
	
})