

function ABComponent(loaderApi){
	"use strict";
	PIXI.DisplayObjectContainer.call(this);
	PIXI.EventTarget.call(this);

	this.loaderApi = loaderApi

	this.imageHolders = [new PIXI.DisplayObjectContainer(), new PIXI.DisplayObjectContainer()];
	this.imageHolders[0].y = 9
	this.imageHolders[1].y = 9
	this.addChild(this.imageHolders[0])
	this.addChild(this.imageHolders[1])

	this.imageOffset = 54;

	this.abText = new PIXI.Text("test", {fill:"#FFFFFF", font:"16px alright_sansultra_italic"}); 
	this.abText.x = this.imageOffset;
	this.abText.y = 26;
	this.abText.alpha = 0;
	this.addChild(this.abText);

	this.textBar = new Bar(60, CONFIG.colorLightBlue);
	this.textBar.x = this.imageOffset;
	this.textBar.y = 27;
	this.textBar.alpha = 0;
	this.addChildAt(this.textBar, 0);


	this.percentageBar = new Bar (60, 0xFFFFFF)
	this.percentageBar.y = 53
	this.addChildAt(this.percentageBar, 0)


	this.percentageText = [new PIXI.Text("test", {fill:"#000000", font:"16px alright_sansultra_italic"}), 
	new PIXI.Text("test", {fill:"#000000", font:"16px alright_sansultra_italic"})];

	this.percentageText[0].y = 52
	this.percentageText[1].y = 52
	this.addChild(this.percentageText[0])
	this.addChild(this.percentageText[1])


	this.imageHolders[0].alpha = 0
	this.imageHolders[1].alpha = 0
	this.percentageBar.alpha = 0;


	this.percentageText[0].alpha = 0;
	this.percentageText[1].alpha = 0;

	this.maskCircle0 = new PixiCircle(38)
	this.maskCircle0.x = 38
	this.maskCircle0.y = 38
	this.imageHolders[0].addChild(this.maskCircle0)

	this.maskCircle1 = new PixiCircle(38)
	this.maskCircle1.y = 38
	this.imageHolders[1].addChild(this.maskCircle1)

	// this.outLine0 = new PIXI.Graphics ();
	// this.outLine0.x = this.maskCircle0.x
	// this.outLine0.y = this.maskCircle0.y
	// this.outLine0.color  = 0xFF0000
	// this.outLine0.lineStyle (4, 0x000643, 1)
	// this.outLine0.drawCircle(0,0, 38)
	// this.imageHolders[0].addChild(this.outLine0)

	// this.outLine1 = new PIXI.Graphics ();
	// this.outLine1.y = this.maskCircle1.y
	// this.outLine1.color  = 0xFF0000
	// this.outLine1.lineStyle (4, 0x000643, 1)
	// this.outLine1.drawCircle(0,0, 38)
	// this.imageHolders[1].addChild(this.outLine1)

  this.__imageLoaded = this.imageLoaded.bind(this)
  this.__imageRemove = this.imageRemove.bind(this)
}


//Copy over prototype --------------------------------------------------------------------------------------

ABComponent.prototype = Object.create(PIXI.DisplayObjectContainer.prototype);
ABComponent.prototype.constructor = ABComponent;

//---------------------------------------------------------------------------------------


// Private function
ABComponent.prototype.imageLoaded = function (e) {

	this.loaderApi.off(LoaderApi.COMPLETE , this.__imageLoaded);

	this.imageSprites = [2];
	this.imageSprites[0] = new PIXI.Sprite(new PIXI.Texture.fromImage(this.imageUrls[0]));
	this.imageSprites[1] = new PIXI.Sprite(new PIXI.Texture.fromImage(this.imageUrls[1]));
	this.imageHolders[0].addChild(this.imageSprites[0]);
	this.imageHolders[1].addChild(this.imageSprites[1]);


	this.imageSprites[0].width = 76
	this.imageSprites[1].width = 76
	this.imageSprites[0].scale.y = this.imageSprites[0].scale.x
	this.imageSprites[1].scale.y = this.imageSprites[1].scale.x
	this.imageSprites[0].x = 0
	this.imageSprites[0].y = 0
	this.imageSprites[1].x = this.maskCircle1.x - this.maskCircle1.radius;
	this.imageSprites[1].y = 0





	this.imageSprites[0].mask = this.maskCircle0;
	this.imageSprites[1].mask = this.maskCircle1;
	// this.imageHolders[0].addChild(this.outLine0)
	// this.imageHolders[1].addChild(this.outLine1)

	// Animation
	// -------------------------------------------------------------------------------------

	this.imageHolders[0].x = -90
	TweenLite.to(this.imageHolders[0] , .4 , {alpha:1, x:0 , delay:0, ease:Strong.easeOut});

	// this.imageHolders[1].x = -90
	TweenLite.to(this.imageHolders[1] , .4 , {alpha:1, x:0 , delay:0, ease:Strong.easeOut});		

	this.percentageBar.scale.x = 0
	TweenLite.to(this.percentageBar.scale , .3 , {x:1, delay:.4, ease:Strong.easeOut});
	TweenLite.to(this.percentageBar , .2 , {alpha:1, delay:.4, ease:Strong.easeOut});

	TweenLite.to(this.textBar , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
	TweenLite.to(this.abText , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
  TweenLite.to(this.percentageText[0] , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
  TweenLite.to(this.percentageText[1] , .4 , {alpha:1, delay:.65, ease:Strong.easeOut});
 }



  ABComponent.prototype.showABResult = function(text, percentages, images){
  	this.imageUrls = images;
  	this.loaderApi.on(LoaderApi.COMPLETE , this.__imageLoaded);
  	this.loaderApi.load(images);
	
  	this.percentageBar.x = this.imageOffset;

  	this.percentageText[0].setText(percentages[0]);
  	this.percentageText[1].setText(percentages[1]);

		this.abText.setText(text);
		var textWidth = this.abText.width;
		this.barWidth = textWidth + 100;
		this.textBar.x = this.imageOffset;
		this.textBar.redraw(this.barWidth);

		this.percentageBar.redraw(this.barWidth);

		this.percentageText[0].x = this.percentageBar.x + 35;
		this.percentageText[1].x = this.percentageBar.x + this.barWidth - (this.percentageText[1].width + 30);

		this.abText.x = this.textBar.x + 19 + 25;

		this.maskCircle1.x = this.textBar.x + this.barWidth + 20;
  }



  ABComponent.prototype.hideABResult = function () {
  	TweenLite.to(this.imageHolders[0] , .2 , {alpha:0, x:-20 , delay:.5, ease:Expo.easeOut});
  	TweenLite.to(this.imageHolders[1] , .2 , {alpha:0, x:-20 , delay:.5, ease:Expo.easeOut, onComplete:this.__imageRemove });
  	TweenLite.to(this.percentageBar , .3 , {x:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.percentageBar , .3 , {alpha:0, delay:.18, ease:Expo.easeOut});
  	TweenLite.to(this.percentageText[0] , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
  	TweenLite.to(this.percentageText[1] , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
		TweenLite.to(this.textBar , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
		TweenLite.to(this.abText , .3 , {x:80, alpha:0, delay:.1, ease:Expo.easeOut});
  	
  }


  ABComponent.prototype.imageRemove = function () {
  	this.imageHolders[0].removeChild(this.imageSprites[0])
  	this.imageHolders[1].removeChild(this.imageSprites[1])
  	this.imageSprites = null
  }